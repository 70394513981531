import top from '../../../assets/images/banner/foundation-top.png'
import bottom from '../../../assets/images/banner/foundation-bottom.png'
import logo from '../../../assets/images/logo/Logo.png'

function SectionFoundation() {
    return (
        <section className="py-16 relative" id="foundation">
            <article className="grid grid-cols-3 gap-12 max-w-container mx-auto px-6 md:px-4">
                <div className="col-span-3 lg:col-span-2 flex flex-col gap-5">
                    <h2 className="font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat">
                        <span className="underlined"><span className="text-primary">GStep</span>&nbsp;Indonesia</span>
                    </h2>
                    <div className="mb-6">
                        <div>
                            Tentang
                            Perusahaan Jasa pelayanan yang berfokus meningkatkan dan mengembangkan skill sumber daya manusia untuk semakin terlatih dan terpercaya menjadi bagian dari unit bisnis sesuai dengan perkembangan dinamika dunia industri

                        </div>
                        <div className="mt-5">
                            <h4 className="font-bold text-[24px] leading-[130%] tracking-wider text-dark montserrat">
                                <span className="underlined">Visi</span>
                            </h4>
                            Menjadi mitra terpercaya bagi perusahaan dan individu dalam membangun masa depan yang lebih baik melalui pengembangan sumber daya manusia, penyaluran dan pemberdayaan tenaga kerja yang berkualitas guna mendorong keberlanjutan di setiap aspek bisnis.

                            <p>
                                <h4 className="font-bold text-[24px] leading-[130%] tracking-wider text-dark montserrat">
                                    <span className="underlined">Misi</span>
                                </h4>
                                <ul className="list-inside text-dark montserrat mt-2">
                                    <li className="flex flex-col mb-2"> {/* Added margin-bottom for gap */}
                                        <div className="flex items-center">
                                            <span className="mr-2">✔️</span>
                                            <strong>Pengembangan Sumber Daya Manusia</strong>
                                        </div>
                                    </li>
                                    <li className="flex flex-col mb-2"> {/* Added margin-bottom for gap */}
                                        <div className="flex items-center">
                                            <span className="mr-2">✔️</span>
                                            <strong>Penyaluran dan Penyediaan Tenaga Kerja</strong>
                                        </div>
                                    </li>
                                    <li className="flex flex-col mb-2"> {/* Added margin-bottom for gap */}
                                        <div className="flex items-center">
                                            <span className="mr-2">✔️</span>
                                            <strong>Konsultasi Manajemen dan CSR</strong>
                                        </div>
                                    </li>
                                    <li className="flex flex-col mb-2"> {/* Added margin-bottom for gap */}
                                        <div className="flex items-center">
                                            <span className="mr-2">✔️</span>
                                            <strong>Kemitraan dan Kolaborasi</strong>
                                        </div>
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-span-3 lg:col-span-1 flex justify-center lg:justify-end">
                    <div className="">
                        <img className="max-w-[300px]" src={logo} alt="" />
                        <img className="max-w-[300px]" src={bottom} alt="" />
                    </div>
                    <div className="relative max-w-[584px] max-h-[410px] lg:w-[584px]">
                        {/* <img className="absolute bottom-0 w-full" src={hero} alt="" /> */}
                    </div>
                </div>
            </article>
        </section>

    );
}

export default SectionFoundation;