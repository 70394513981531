import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import TabTitle from "../../../utils/TabTitle";
import ModalAlert from "../../../components/ModalAlert";
import React, { useState } from "react";
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from "../../../assets/images/logo/Logo.png";
import Icon from "@mdi/react";
import { mdiArrowLeft } from "@mdi/js";
import Footer from "../../../components/LandingPage/Footer";
import { mdiCircleEditOutline } from "@mdi/js";
import NavProfile from "../Component/NavProfile";
import Button from "../../../components/Button";
import TextField from "../../../components/TextField";

const ChangePasswordPage = (props) => {
    TabTitle("Profile");
    const navigate = useNavigate();

    // global condition component
    const [modalOpen, setModalOpen] = useState({ status: false, type: "" });
    const [modalAlert, setModalAlert] = useState({
        alertTitle: "",
        alertMessage: "",
    });

    const handleBack = () => {
        navigate("/");
    };

    return (
        <>
            <ModalAlert
                isOpen={modalOpen.status && modalOpen.type === "alert"}
                closeModal={() => {
                    setModalOpen({ status: false, type: "" });
                    setModalAlert({ alertTitle: "", alertMessage: "" });
                }}
                alertTitle={modalAlert.alertTitle}
                alertMessage={modalAlert.alertMessage}
            />
            <header id="header" className="top-0 z-50 sticky">
                <nav className="border-b bg-white border-b-bright">
                    <div className="grid grid-cols-12 mx-auto lg:max-w-7xl py-5 px-4 lg:px-4 2xl:px-0">
                        <div className="col-span-2 flex items-center justify-between">
                            <img src={logo} alt="CleanSheet" className="h-9 hidden sm:block" />
                            <button className="h-9 block mr-0 sm:mr-5" onClick={handleBack}>
                                <Icon path={mdiArrowLeft} size={1} className="text-primary" />
                            </button>
                        </div>
                        <div className="col-span-10 grid grid-cols-10">
                            <div className="col-span-10">
                                <span className="not-italic montserrat text-2xl font-bold leading-[140%] tracking-[0.005em] text-primary">
                                    Akun
                                </span>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>

            <section className="font-light leading-relaxed grid grid-rows-12 grid-flow-col grid-cols-12 mx-auto px-4 lg:px-4 xl:px-0 lg:max-w-6xl relative gap-4 lg:gap-y-6 lg:gap-x-10 py-20">
                <NavProfile />
                <div className="row-span-2 col-span-8 bg-white rounded-3xl drop-shadow-bottom">

                    <div className="border-b-2 p-4 border-bright flex">
                        <p className="text-2xl text-primary font-bold">Ganti Kata Sandi</p>
                    </div>

                    <div className="flow-root p-4">
                        <form
                            className='flex-grow grid grid-cols-2 gap-3 w-full mb-4'>

                            <TextField
                                label='Kata Sandi Saat Ini'
                                isRequired
                                placeholder='Masukkan kata sandi saat ini'
                                type='password'
                                inputId='currentPassword'
                                inputName='currentPassword'
                                customLabelStyle='col-span-4 sm:col-span-1'
                                customContainerStyle='col-span-4 sm:col-span-3 md:col-span-2 md:col-end-5'
                            />
                            <TextField
                                label='Kata Sandi Baru'
                                isRequired
                                placeholder='Ketik Kata Sandi Baru'
                                type='password'
                                inputId='newPassword'
                                inputName='newPassword'
                                customLabelStyle='col-span-4 sm:col-span-1'
                                customContainerStyle='col-span-4 sm:col-span-3 md:col-span-2 md:col-end-5'
                            />
                            <TextField
                                label='Ketik Ulang Sandi Baru'
                                isRequired
                                placeholder='Ketik Ulang Kata Sandi Baru'
                                type='password'
                                inputId='confirmNewPassword'
                                inputName='confirmNewPassword'
                                customLabelStyle='col-span-4 sm:col-span-1'
                                customContainerStyle='col-span-4 sm:col-span-3 md:col-span-2 md:col-end-5'
                            />

                        </form>
                        <div className='flex flex-row justify-end'>
                            <Button
                                typeNavigation='button'
                                btnStyle='primary'
                                btnType="primary"
                                customStyle='px-4 py-0.5 w-fit rounded-full'>
                                Ubah Kata Sandi
                            </Button>
                        </div>
                    </div>
                </div>
            </section>
            <FloatingWhatsapp />
            <footer id="footer">
                <Footer />
            </footer>
        </>
    );
}

export default connect(null)(ChangePasswordPage)