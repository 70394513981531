import React from "react";
import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import SectionDalamAngka from "../../../components/LandingPage/HomeSection/SectionDalamAngka";
import SectionMedia from "../../../components/LandingPage/HomeSection/SectionMedia";
import SectionPartnerKlienKami from "../../../components/LandingPage/HomeSection/SectionPartnerKlienKami";
import SectionPartnerTestimoniPelanggan from "../../../components/LandingPage/HomeSection/SectionPartnerTestimoniPelanggan";
import SectionVideoSliderPromo from "../../../components/LandingPage/HomeSection/SectionVideoSliderPromo";
import SectionJasa from "../../../components/LandingPage/SectionJasa";
import TabTitle from "../../../utils/TabTitle";

const PartnerPage = () => {
  TabTitle("Partner - GStep");
  return (
    <>
      <SectionPartnerKlienKami />
      <SectionPartnerTestimoniPelanggan />
      <SectionMedia />
      <FloatingWhatsapp />
    </>
  );
};

export default PartnerPage;
