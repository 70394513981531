import {mdiWhatsapp} from "@mdi/js";
import Icon from "@mdi/react";
function FloatingWhatsapp() {
  return (
    <button
      className="whatsapp flex justify-center z-[47] right-7 bottom-8"
      type="button"
      title="Customer Service - WhatsApp"
      aria-label="Cleansheet Customer Service"
      onClick={(e) => {
        e.preventDefault();
        window.location.href = "https://wa.me/6285935140788";
      }}
    >
      <Icon
        path={mdiWhatsapp}
        size={2}
        className="flex self-center text-white"
      />
    </button>
  );
}

export default FloatingWhatsapp;
