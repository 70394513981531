import {
    mdiArrowLeftCircleOutline,
    mdiArrowRightCircleOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper";
import React from 'react';
import model from "../../../assets/images/misc/banner.png";
import model2 from "../../../assets/images/misc/banner-2.jpg"
import model3 from "../../../assets/images/misc/banner-3.jpg"
import model4 from "../../../assets/images/misc/banner-4.jpg"
import model5 from "../../../assets/images/misc/banner-5.jpg"
import "swiper/css"; // Import Swiper styles

const images = [
    model,
    model2,
    model3,
    model4,
    model5,
    model
    // Add more image paths as needed
];

const SectionGallery = () => {
    return (
        <>
            <section
                className="relative overflow-hidden py-16 bg-service-texture bg-cover"
                id="layanan-kami"
            >
                <article className="md:max-w-container mx-auto px-6 md:px-4">
                    <div className="flex flex-col justify-start md:justify-center">
                        <div className="font-bold flex flex-row flex-wrap justify-start md:justify-center text-2xl lg:text-[32px] leading-[140%] montserrat">
                            Galeri
                        </div>
                    </div>
                </article>
                <aside className="mt-[46px] min-w-0 md:max-w-container mx-auto px-0 sm:px-4 grid grid-cols-1 md:grid-cols-12">
                    <button
                        className="hidden md:flex justify-start col-span-1 gallery-swiper-button-prev disabled:opacity-50"
                        title="Navigate Previous Slide"
                        aria-label="Navigate Previous Service Slide"
                    >
                        <Icon
                            path={mdiArrowLeftCircleOutline}
                            size={2}
                            className="flex justify-center self-center text-blue-gray-500"
                        />
                    </button>
                    <div className="col-span-2 md:col-span-10">
                        <div className="w-full md:max-w-[2048px]">
                            <Swiper
                                modules={[Navigation, Pagination]}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 2,
                                        centeredSlides: false,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        centeredSlides: false,
                                    },
                                }}
                                spaceBetween={0}
                                pagination={{
                                    el: ".gallery-pagination",
                                    clickable: true,
                                    renderBullet: function (index, className) {
                                        return '<span class="' + className + '"></span>';
                                    },
                                }}
                                navigation={{
                                    nextEl: ".gallery-swiper-button-next",
                                    prevEl: ".gallery-swiper-button-prev",
                                }}
                                loop={false}
                            >
                                {images.map((image, index) => (
                                    <SwiperSlide>
                                        <div className="rounded-[24px] p-3 h-full flex flex-col gap-4">
                                            <div className="w-full h-[200px] rounded-md overflow-hidden">
                                                <img src={image} alt={`Gallery item ${index + 1}`} className="object-cover w-full h-full" />
                                                {/* Removed the "Lihat Detail" button and its container */}
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                    <button
                        className="hidden col-span-1 md:flex justify-end gallery-swiper-button-next disabled:opacity-50"
                        title="Navigate Next Slide"
                        aria-label="Navigate Next Service Slide"
                    >
                        <Icon
                            path={mdiArrowRightCircleOutline}
                            size={2}
                            className="self-center text-blue-gray-500"
                        />
                    </button>
                </aside>
                <div
                    className="mt-[24px] md:max-w-container mx-auto flex justify-center px-6 md:px-4"
                    aria-label="service-navigation"
                >
                    <div className="gallery-pagination block whitespace-nowrap"></div>
                </div>
                <div className="absolute h-full bg-light w-full bottom-0 -z-10"></div>
            </section>

        </>
    );
};

export default SectionGallery;
