import React from "react";
import Button from "../../../components/Button";
import {connect, useSelector} from 'react-redux';
import Icon from "@mdi/react";
import {NavLink} from "react-router-dom";
import {mdiLockOutline, mdiTicketPercentOutline, mdiCartOutline, mdiHomeMapMarker, mdiLogout, mdiAccount, mdiHandCoin, mdiContactlessPayment, mdiCreditCard} from "@mdi/js";

const NavProfile = (props) => {
    const auth = useSelector(state => state.auth);
    const profile = useSelector(state => state.profile);

    const handleLogout = () => {
        localStorage.clear();
        window.location.href = '/login';
    }

    return (
        <>
            <div className="row-span-6 col-span-4 p-4 bg-white rounded-3xl drop-shadow-bottom">
                <div className="divide-y divide-bright dark:divide-gray-700">
                    <NavLink to="/profile" className="flex justify-between items-center py-3 px-2 hover:bg-blue-50">
                        <div className="inline-flex items-center space-x-2">
                            <Icon path={mdiAccount} size={0.8} className="text-primary" />
                            <div>Profil</div>
                        </div>
                    </NavLink>
                    <NavLink to="/profile/addresses" className="flex justify-between items-center py-3 px-2 hover:bg-blue-50">
                        <div className="inline-flex items-center space-x-2">
                            <Icon path={mdiHomeMapMarker} size={0.8} className="text-primary" />
                            <div>Alamat Saya</div>
                        </div>
                    </NavLink>
                    <NavLink to="/profile/orders" className="flex justify-between items-center py-3 px-2 hover:bg-blue-50">
                        <div className="inline-flex items-center space-x-2">
                            <Icon path={mdiCartOutline} size={0.8} className="text-primary" />
                            <div>Reservasi</div>
                        </div>
                    </NavLink>
                    <NavLink to="/profile/vouchers" className="flex justify-between items-center py-3 px-2 hover:bg-blue-50">
                        <div className="inline-flex items-center space-x-2">
                            <Icon path={mdiTicketPercentOutline} size={0.8} className="text-primary" />
                            <div>Voucher Saya</div>
                        </div>
                    </NavLink>
                    <NavLink to="/profile/payments" className="flex justify-between items-center py-3 px-2 hover:bg-blue-50">
                        <div className="inline-flex items-center space-x-2">
                            <Icon path={mdiCreditCard} size={0.8} className="text-primary" />
                            <div>Default Payment</div>
                        </div>
                    </NavLink>
                    <NavLink to="/profile/password" className="flex justify-between items-center py-3 px-2 hover:bg-blue-50">
                        <div className="inline-flex items-center space-x-2">
                            <Icon path={mdiLockOutline} size={0.8} className="text-primary" />
                            <div>Ganti Kata Sandi</div>
                        </div>
                    </NavLink>
                    <div onClick={handleLogout} className="cursor-pointer flex justify-between items-center py-3 px-2 hover:bg-blue-50">
                        <div className="inline-flex items-center space-x-2">
                            <Icon path={mdiLogout} size={0.8} className="text-primary" />
                            <div>Logout</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default connect(null)(NavProfile)
