import SectionHeadline from "../../../components/LandingPage/BlogSection/SectionHeadline";
import SectionNewNews from "../../../components/LandingPage/BlogSection/SectionNewNews";
import SectionTodayNews from "../../../components/LandingPage/BlogSection/SectionTodayNews";
import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import SectionApp from "../../../components/LandingPage/HomeSection/SectionApp";
import TabTitle from "../../../utils/TabTitle";

const BlogPage = () => {
    TabTitle("Blog - GStep");
    return (
        <>
            {/* <SectionSlider /> */}
            <SectionHeadline />
            <SectionTodayNews />
            <SectionNewNews />
            <SectionApp />
            <FloatingWhatsapp />
        </>
    );
}

export default BlogPage;