import {Navigate, Outlet} from "react-router-dom";
import {connect} from 'react-redux';
import React from "react";

const accessToken = localStorage.getItem('accessToken');

/** protect private route */
const PrivateRoute = (props) => (
    accessToken? <Outlet/> : <Navigate to={{
        pathname: '/login',
        state: { from: props.location }
    }} />
)

const mapStateToProps = (state) => {
    return{
      auth: state.auth,
    }
}

export default connect (mapStateToProps) (PrivateRoute);
