import React from "react";
import {ReactComponent as Phone} from "../../../assets/vectors/icons/phone-icon.svg";
import {ReactComponent as MapMarker} from "../../../assets/vectors/icons/map-marker-icon.svg";
import {ReactComponent as Clock} from "../../../assets/vectors/icons/clock-icon.svg";

function SectionInfo() {
  return (
    <section
      id="info"
      className="bg-primaryDark bg-about-texture min-h-[96px] flex justify-start px-6 md:px-4 lg:justify-center"
    >
      <div className="py-[19px] max-w-full lg:max-w-[1000px] flex flex-col lg:flex-row gap-6 z-20">
        <div className="flex flex-col lg:flex-row basis-full lg:basis-1/3">
          <div className="flex flex-row grow items-center">
            <div>
              <Clock className="self-center text-bright" />
            </div>
            <div className="text-white font-normal leading-[140%] tracking-wider text-sm self-center ml-2 lg:ml-[21.79px]">
              <dd>Email : </dd>
              <dt>admin@gstepindonesia.com</dt>
            </div>
          </div>
          <div className="w-[1px] hidden lg:block bg-bright mx-2 lg:mx-[24px]"></div>
        </div>
        <div className="flex flex-col lg:flex-row basis-full lg:basis-1/3">
          <div className="flex flex-row grow items-center">
            <div>
              <Phone className="self-center text-bright" />
            </div>
            <div className="text-white font-normal leading-[140%] tracking-wider text-sm self-center ml-2 lg:ml-[21.79px]">
              <dd>Whatsapp:</dd>
              <dt>62-859-3514-0788</dt>
            </div>
          </div>
          <div className="w-[1px] hidden lg:block bg-bright mx-2 lg:mx-[24px]"></div>
        </div>
        <div className="flex flex-col lg:flex-row basis-full lg:basis-1/3">
          <div className="flex flex-row items-center">
            <div>
              <MapMarker className="self-center text-bright" />
            </div>
            <div className="text-white font-normal leading-[140%] tracking-wider text-sm self-center ml-2 lg:ml-[21.79px] ">
              <dd>Head Office : </dd>
              <dt> Gedung Startup Center Lt 3 Jl. Taman Kencana No.3, Babakan, Kecamatan Bogor Tengah, Kota Bogor, Jawa Barat 16128</dt>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionInfo;
