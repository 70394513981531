import logo from "../../assets/images/logo/LogoFooter.png";
import {mdiFacebook, mdiTwitter, mdiInstagram} from "@mdi/js";
import Icon from "@mdi/react";
import {Link} from "react-router-dom";
function Footer() {
  return (
    <div className="bg-dark lato text-white font-normal leading-[140%] tracking-wider text-sm">
      <div className="grid grid-cols-1 md:grid-cols-12 px-6 pt-16 pb-10 gap-4 md:max-w-container mx-auto">
        <div className="col-span-1 md:col-span-10 flex flex-col">
          <div>
            <span className=" font-bold leading-[140%] tracking-wider">
              Hak Cipta © 2024 GStep Indonesia.
            </span>{" "}
            Semua Hak Dilindungi Undang-Undang. Merek kata CleanSheet adalah
            merek dagang terdaftar dari Kementerian Hukum dan HAM RI Direktorat
            Jenderal Hak Kekayaan Intelektual.
          </div>
          <div className="my-[26px] p-[1px] bg-default"></div>
          <div className="md:flex justify-between">
            <nav
              aria-label="bottom-navigation"
              className="grid grid-cols-1 lg:flex justify-between gap-4 lg:gap-8 mb-6"
            >
              <Link to={"/about"}>
                <div className="flex items-start">Tentang Kami</div>
              </Link>
              <Link
                to={{pathname: "https://wa.me/6281297984971"}}
                target="_blank"
              >
                <div className="flex items-start">Hubungi Kami</div>
              </Link>
              <button className="flex flex-start">Kantor Cabang</button>
              <Link to={"/services"}>
                <div className="flex items-start">Layanan Kami</div>
              </Link>
              <button className="flex flex-start">Sitemap</button>
            </nav>
            <div className="flex lg:justify-between gap-[28px]">
              <a
                href="https://facebook.com/cleansheetindonesia/"
                className="flex justify-end items-start"
              >
                <Icon path={mdiFacebook} size={0.8} className="text-primary" />
              </a>
              <button className="flex justify-end items-start">
                <Icon path={mdiTwitter} size={0.8} className="text-primary" />
              </button>
              <a
                href="https://www.instagram.com/cleansheet_id/"
                className="flex justify-end items-start"
              >
                <Icon path={mdiInstagram} size={0.8} className="text-primary" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
