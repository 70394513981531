import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import SectionApp from "../../../components/LandingPage/HomeSection/SectionApp";
import TabTitle from "../../../utils/TabTitle";
import ModalAlert from "../../../components/ModalAlert";
import React, {useState} from "react";
import TextField from "../../../components/TextField";
import Button from "../../../components/Button";
import { postLogin } from '../../../services/Actions/Auth';
import { getProfile } from '../../../services/Actions/Profile';
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {NavLink} from "react-router-dom";

const LoginPage = (props) => {
    TabTitle("Login");
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth);

    // global condition component
    const [modalOpen, setModalOpen] = useState({status: false, type: ""});
    const [modalAlert, setModalAlert] = useState({
        alertTitle: "",
        alertMessage: "",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);
        if (!data.email || !data.password) {
            alert('tidak boleh kosong')
        }else{
            await props.dispatch(postLogin(data))
                .then((res) => {
                    let token = res?.value?.data?.data?.accessToken??null
                    let type = res?.value?.data?.data?.tokenType??null
                    localStorage.setItem('accessToken', token)
                    localStorage.setItem('tokenType', type)
                    props.dispatch(getProfile())
                    navigate('/')
                })
                .catch((error) => {
                    setModalAlert({
                        alertTitle: `Error ${error.response.status} ${process.env.REACT_APP_API_URL}`,
                        alertMessage:
                            error.response.status === 0
                                ? "Terjadi kesalahan saat memperoleh data dari server."
                                : error.response?.data?.error?.message??error.message,
                    });
                    setModalOpen({status: true, type: "alert"});
                })
        }
    }

    return (
        <>
            <ModalAlert
                isOpen={modalOpen.status && modalOpen.type === "alert"}
                closeModal={() => {
                    setModalOpen({status: false, type: ""});
                    setModalAlert({alertTitle: "", alertMessage: ""});
                }}
                alertTitle={modalAlert.alertTitle}
                alertMessage={modalAlert.alertMessage}
            />
            <section className="grid grid-cols-1 sm:grid-cols-12 mx-auto px-4 lg:px-4 xl:px-0 lg:max-w-7xl relative gap-10 lg:gap-y-6 lg:gap-x-10 py-20">
                <div className="col-span-1 sm:col-span-12 lg:col-end-10 lg:col-span-5 p-6 bg-white rounded-3xl drop-shadow-bottom">
                    <div className="flex flex-col gap-1 pb-10">
                        <div className="font-bold flex justify-start md:justify-center sm:text-[24px] leading-[140%] tracking-wider montserrat">Selamat Datang!</div>
                        <div className="flex justify-start md:justify-center text-left md:text-center sm:text-[18px] leading-[140%] tracking-wider ">Prioritas kami adalah melayani Anda dengan sepenuh hati.</div>
                    </div>
                    <form className="flex flex-col gap-6"
                          id="form-login"
                          onSubmit={handleSubmit}
                    >
                        <div className="flex flex-col gap-2">
                            <TextField
                                isRequired={true}
                                label={"Email"}
                                inputId={"email"}
                                type={"text"}
                                inputName={"email"}
                                placeholder={"Masukkan email anda"}
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <TextField
                                isRequired={true}
                                label={"Password"}
                                inputId={"password"}
                                type={"password"}
                                inputName={"password"}
                                placeholder={"Masukkan password anda"}
                            />
                        </div>

                        <Button type="submit" form={"form-login"} btnType="primary" isDisabled={auth.isLoading}>
                            <div className="w-full text-center">{auth.isLoading? 'Loading...' : 'Masuk'}</div>
                        </Button>

                        <div className="flex flex-col gap-1 pb-10">
                            <div
                                className="flex justify-start md:justify-center text-left md:text-center sm:text-[18px] leading-[140%] tracking-wider">
                                Belum memiliki akun?&nbsp;<NavLink to="/register" className="text-primary">daftar di sini</NavLink>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        <SectionApp />
        <FloatingWhatsapp />
        </>
    );
}

export default connect (null) (LoginPage)
