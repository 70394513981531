import React from "react";
import ms from "../../../assets/images/brands/mandiri-syariah.png";
import dd from "../../../assets/images/brands/dompet-dhuafa.png";
import kd from "../../../assets/images/brands/kata-data.png";
import jc from "../../../assets/images/brands/jica.png";
import rd from "../../../assets/images/brands/ristekdikti.png";
import {Swiper, SwiperSlide} from "swiper/react";
import {Mousewheel} from "swiper";
import {NavLink} from "react-router-dom";

const SectionOurClient = () => {
  return (
    <section className="w-full pt-6 pb-16 relative" id="klien-kami">
      <article className="md:max-w-container mx-auto px-6 md:px-4">
        <div className="pb-8 flex flex-col gap-4">
          <div className="flex justify-start lg:justify-center sm:text-xl md:text-[22px] font-normal leading-[140%] tracking-wider">
            KLIEN KAMI
          </div>
          <div className="font-bold flex flex-wrap justify-start lg:justify-center text-2xl md:text-[32px] leading-[140%] montserrat whitespace-nowrap">
            Kami Sudah Melayani&nbsp;
            <span>
              <span className="text-primary">1000+</span>&nbsp;Perusahaan
            </span>
          </div>
        </div>
        <div className="py-6">
          <Swiper
            modules={[Mousewheel]}
            breakpoints={{
              320: {
                slidesPerView: 2,
              },
              640: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 5,
              },
            }}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            mousewheel={true}
            className="flex items-center"
          >
            <SwiperSlide>
              <img src={ms} alt="" className="max-h-[3rem]" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={dd} alt="" className="max-h-[3rem]" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={kd} alt="" className="max-h-[3rem]" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={jc} alt="" className="max-h-[3rem]" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={rd} alt="" className="max-h-[3rem]" />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="text-base font-semibold leading-[140%] tracking-wider flex justify-center">
          <NavLink to={"/client"}>Cek daftar klien kami</NavLink>
        </div>
      </article>
    </section>
  );
};

export default SectionOurClient;
