import {mdiThumbUpOutline} from "@mdi/js";
import Icon from "@mdi/react";
import {Swiper, SwiperSlide} from "swiper/react";
import blog1 from "../../../assets/images/articles/blog1.png";
import blog2 from "../../../assets/images/articles/blog2.jpg";
import blog3 from "../../../assets/images/articles/blog3.jpeg";
import blog4 from "../../../assets/images/articles/blog4.jpg";
import blog5 from "../../../assets/images/articles/blog5.jpg";
import blog6 from "../../../assets/images/articles/blog6.jpg";
import blog7 from "../../../assets/images/articles/blog7.jfif";
import blog8 from "../../../assets/images/articles/blog8.jpg";
import blog9 from "../../../assets/images/articles/blog9.jpg";
import blog10 from "../../../assets/images/articles/blog10.jfif";
import blog11 from "../../../assets/images/articles/blog11.jpg";
import blog12 from "../../../assets/images/articles/blog12.jfif";
import Button from "../../Button";
function SectionBlog() {
  return (
    <section
      id="blog-artikel-pilihan"
      className="py-16 border-b-[1px] border-b-bright"
    >
      <article className="max-w-container mx-auto px-6 md:px-4">
        <div className="font-bold mb-[40px] flex justify-start md:justify-center sm:text-[32px] leading-[140%] tracking-wider montserrat">
          Artikel Pilihan untuk Anda
        </div>
        <Swiper
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          className="flex items-center"
        >
          <SwiperSlide>
            <div className="flex flex-col">
              <img
                src={blog1}
                alt="Langkah Kecil Untuk Indonesia"
                className="w-full h-[250px] lg:h-[300px] rounded-3xl"
              />
              <div className="my-4 text-dark leading-[140%] tracking-wider font-bold text-sm">
                LANGKAH KECIL UNTUK INDONESIA
              </div>
              <a
                href="http://www.kickandy.com/show/langkah-kecil-untuk-indonesia"
                className="text-primary"
              >
                Baca Selengkapnya
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col">
              <img
                src={blog2}
                alt="Dihqon Nadaamist"
                className="w-full h-[250px] lg:h-[300px] rounded-3xl"
              />
              <div className="my-4 text-dark leading-[140%] tracking-wider font-bold text-sm">
                Dihqon Nadaamist, Bersih-bersih Menyambung Harapan
              </div>
              <a
                href="https://www.kompas.id/baca/sosok/2022/04/24/dihqon-nadaamist-bangun-jasa-pembersihan-untuk-bantu-siswa-do"
                className="text-primary"
              >
                Baca Selengkapnya
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col">
              <img
                src={blog3}
                alt=""
                className="w-full h-[250px] lg:h-[300px] rounded-3xl"
              />
              <div className="my-4 text-dark leading-[140%] tracking-wider font-bold text-sm">
                Dihqon Nadaamist: Jembatan Mahasiswa Mandiri
              </div>
              <a
                href="https://mediaindonesia.com/weekend/337054/dihqon-nadaamist-jembatan-mahasiswa-mandiri"
                className="text-primary"
              >
                Baca Selengkapnya
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col">
              <img
                src={blog4}
                alt=""
                className="w-full h-[250px] lg:h-[300px] rounded-3xl"
              />
              <div className="my-4 text-dark leading-[140%] tracking-wider font-bold text-sm">
                Dihqon Naadamist, Alumnus IPB University yang Bercita-cita
                Sekolahkan 1000 Anak Lewat Cleansheet
              </div>
              <a
                href="http://dikti.go.id/kabar-dikti/kampus-kita/dihqon-naadamist-alumnus-ipb-university-yang-bercita-cita-sekolahkan-1000-anak-lewat-cleansheet/"
                className="text-primary"
              >
                Baca Selengkapnya
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col">
              <img
                src={blog5}
                alt=""
                className="w-full h-[250px] lg:h-[300px] rounded-3xl"
              />
              <div className="my-4 text-dark leading-[140%] tracking-wider font-bold text-sm">
                Jasa Bersih Rumah Raup Cuan saat Libur Lebaran
              </div>
              <a
                href="https://www.inews.id/finance/bisnis/jasa-bersih-rumah-raup-cuan-saat-libur-lebaran"
                className="text-primary"
              >
                Baca Selengkapnya
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col">
              <img
                src={blog6}
                alt=""
                className="w-full h-[250px] lg:h-[300px] rounded-3xl"
              />
              <div className="my-4 text-dark leading-[140%] tracking-wider font-bold text-sm">
                Cleansheet Terpilih sebagai “Best of the Best Startup” di The
                NextDev Talent Scouting
              </div>
              <a
                href="https://blog.thenextdev.id/2022/03/cleansheet-terpilih-sebagai-best-of-the-best-startup-di-the-nextdev-talent-scouting/"
                className="text-primary"
              >
                Baca Selengkapnya
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col">
              <img
                src={blog7}
                alt=""
                className="w-full h-[250px] lg:h-[300px] rounded-3xl"
              />
              <div className="my-4 text-dark leading-[140%] tracking-wider font-bold text-sm">
                ART Pulang Kampung saat Lebaran, Jasa Bersih Rumah Langsung
                Banjir Cuan
              </div>
              <a
                href="https://economy.okezone.com/read/2022/05/11/320/2592238/art-pulang-kampung-saat-lebaran-jasa-bersih-rumah-langsung-banjir-cuan"
                className="text-primary"
              >
                Baca Selengkapnya
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col">
              <img
                src={blog8}
                alt=""
                className="w-full h-[250px] lg:h-[300px] rounded-3xl"
              />
              <div className="my-4 text-dark leading-[140%] tracking-wider font-bold text-sm">
                Inspiratif! Pemuda Ini Bantu Mahasiswa dan Anak Putus Sekolah
                Melalui Usaha Home Cleaning Service
              </div>
              <a
                href="https://mnews.co.id/read/anak-muda-punya-cerita/inspiratif-pemuda-ini-bantu-mahasiswa-dan-anak-putus-sekolah-melalui-usaha-home-cleaning-service/"
                className="text-primary"
              >
                Baca Selengkapnya
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col">
              <img
                src={blog9}
                alt=""
                className="w-full h-[250px] lg:h-[300px] rounded-3xl"
              />
              <div className="my-4 text-dark leading-[140%] tracking-wider font-bold text-sm">
                Cleansheet Siap Tebar Manfaat Dengan Sekolahkan 1000 Anak
              </div>
              <a
                href="https://visioner.id/default/19802/cleansheet-siap-tebar-manfaat-dengan-sekolahkan-1000-anak.html"
                className="text-primary"
              >
                Baca Selengkapnya
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col">
              <img
                src={blog10}
                alt=""
                className="w-full h-[250px] lg:h-[300px] rounded-3xl"
              />
              <div className="my-4 text-dark leading-[140%] tracking-wider font-bold text-sm">
                CleanSheet Startup Terbaik The NextDev Talent Scouting 2021
              </div>
              <a
                href="https://tekno.sindonews.com/read/688191/207/cleansheet-startup-terbaik-the-nextdev-talent-scouting-2021-1645020167"
                className="text-primary"
              >
                Baca Selengkapnya
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col">
              <img
                src={blog11}
                alt=""
                className="w-full h-[250px] lg:h-[300px] rounded-3xl"
              />
              <div className="my-4 text-dark leading-[140%] tracking-wider font-bold text-sm">
                CleanSheet Jadi Jawara The NextDev 2021
              </div>
              <a
                href="https://www.tribunnews.com/techno/2022/02/17/cleansheet-jadi-jawara-the-nextdev-2021"
                className="text-primary"
              >
                Baca Selengkapnya
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col">
              <img
                src={blog12}
                alt=""
                className="w-full h-[250px] lg:h-[300px] rounded-3xl"
              />
              <div className="my-4 text-dark leading-[140%] tracking-wider font-bold text-sm">
                Cleansheet Terpilih sebagai “Best of the Best Startup” di The
                NextDev Talent Scouting
              </div>
              <a
                href="https://teknologi.bisnis.com/read/20220216/101/1501359/telkomsel-umumkan-12-startup-terbaik-the-nextdev-talent-scouting-2021-dukung-pencipta-perubahan-untuk-melesat-dengan-digital"
                className="text-primary"
              >
                Baca Selengkapnya
              </a>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="flex justify-center mt-16">
          <a href="https://cleansheet.id/blog/">
            <Button btnType="secondary" customStyle=" rounded-full">
              <div className="px-5 py-1 font-bold not-italic text-base leading-[140%] tracking-[0.005em] text-dark">
                Lihat Semua Artikel
              </div>
            </Button>
          </a>
        </div>
      </article>
    </section>
  );
}

export default SectionBlog;
