import {
  mdiClose,
  mdiEmailOutline,
  mdiFacebook,
  mdiGoogleMaps,
  mdiInstagram,
  mdiMenu,
  mdiPhoneOutline,
  mdiTwitter,
} from "@mdi/js";
import Icon from "@mdi/react";
import logo from "../../assets/images/logo/Logo.png";
import en from "../../assets/images/icons/en.png";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import Button from "../Button";
import Dropdown from "../Dropdown";

function NavBar() {
  const [isMobile, setIsMobile] = useState(false);
  const toggleMenu = () => setIsMobile(!isMobile);

  const serviceItems = [
    {
      title: "Public Training",
      path: "public-training"
    },
    {
      title: "Inhouse Training",
      path: "inhouse-training"
    },
    {
      title: "Man Power Supply",
      path: "man-power-supply"
    }
  ];

  return (
    <div className="navbar-container bg-white lg:border-b lg:border-b-primary flex flex-col overflow-visible relative h-full" aria-label="main-navbar">
      {/* Desktop */}
      <div className="bg-white border-b border-b-primary w-full md:max-w-container mx-auto lg:border-b-0 py-6 px-6 grid grid-cols-2 md:grid-cols-12 gap-16">
        <div className="col-span-1 md:col-span-2 flex items-center justify-center">
          <a href="/" title="GStep Indonesia" aria-label="GStep Logo">
            <img className="h-auto w-auto" src={logo} alt="GStep" />
          </a>
        </div>
        <div className="md:hidden col-end-3 col-span-1 flex justify-end">
          {!isMobile ? (
            <button
              type="button"
              onClick={toggleMenu}
              title="Open menu"
              className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              aria-expanded="false"
              aria-label="Toggle Open Navigation"
            >
              <span className="sr-only">Open menu</span>
              <Icon path={mdiMenu} size={1} className="text-primary" />
            </button>
          ) : (
            <button
              type="button"
              onClick={toggleMenu}
              title="Close menu"
              className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              aria-expanded="false"
              aria-label="Toggle Close Navigation"
            >
              <span className="sr-only">Close menu</span>
              <Icon path={mdiClose} size={1} className="text-primary" />
            </button>
          )}
        </div>
        <ul className="hidden md:col-span-10 md:flex flex-row items-stretch justify-end">
          <li className="flex items-center">
            <NavLink
              to={"/"}
              title="Beranda"
              aria-label="Beranda"
              className={({ isActive }) =>
                `flex items-center px-2 text-sm font-bold leading-[140%] tracking-wider ${isActive ? "text-primary" : "text-dark hover:text-primary"}`
              }
            >
              Beranda
            </NavLink>
          </li>
          <li className="flex items-center">
            <NavLink
              to={"/about"}
              title="Tentang GStep"
              aria-label="Tentang GStep"
              className={({ isActive }) =>
                `flex items-center px-2 text-sm font-bold leading-[140%] tracking-wider ${isActive ? "text-primary" : "text-dark hover:text-primary"}`
              }
            >
              Tentang Kami
            </NavLink>
          </li>
          <li className="dropdown-container relative h-full flex items-center">
            <Dropdown label="Layanan" items={serviceItems} isMobile={false} />
          </li>

          <li className="flex items-center">
            <NavLink
              to={"/project-us"}
              title="Jadwal Pelatihan"
              aria-label="Jadwal Pelatihan"
              className={({ isActive }) =>
                `flex items-center px-2 text-sm font-bold leading-[140%] tracking-wider ${isActive ? "text-primary" : "text-dark hover:text-primary"}`
              }
            >
              Jadwal Pelatihan
            </NavLink>
          </li>
          <li className="flex items-center">
            <NavLink
              to={"/client"}
              title="Klien Kami"
              aria-label="Klien Kami"
              className={({ isActive }) =>
                `flex items-center px-2 text-sm font-bold leading-[140%] tracking-wider ${isActive ? "text-primary" : "text-dark hover:text-primary"}`
              }
            >
              Klien Kami
            </NavLink>
          </li>
          <li className="flex items-center">
            <NavLink
              to={"/blog"}
              title="Blog"
              aria-label="Blog"
              className={({ isActive }) =>
                `flex items-center px-2 text-sm font-bold leading-[140%] tracking-wider ${isActive ? "text-primary" : "text-dark hover:text-primary"}`
              }
            >
              Blog
            </NavLink>
          </li>
          <li className="flex items-center ml-2">
            <NavLink to="https://wa.me/6285935140788" title="Hubungi" aria-label="Hubungi">
              <Button customStyle="rounded-full">
                <div className="montserrat not-italic font-bold leading-[22px] text-center text-sm tracking-[0.005em] px-2.5">
                  Hubungi
                </div>
              </Button>
            </NavLink>
          </li>
        </ul>
      </div>

      {/* Mobile */}
      {isMobile && (
        <div className="md:hidden relative">
          <div className="border-b border-b-primary bg-white py-6 px-5 overflow-y-auto flex flex-col gap-8">
            <ul className="flex flex-col gap-8">
              <li>
                <NavLink
                  to={"/"}
                  title="Beranda"
                  aria-label="Beranda"
                  onClick={() => setIsMobile(false)}
                  className={({ isActive }) =>
                    `block px-4 py-2 text-sm font-bold leading-[140%] tracking-wider ${isActive ? "text-primary" : "text-dark"}`
                  }
                >
                  Beranda
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/about"}
                  title="Tentang CleanSheet"
                  aria-label="Tentang CleanSheet"
                  onClick={() => setIsMobile(false)} 
                  className={({ isActive }) =>
                    `block px-4 py-2 text-sm font-bold leading-[140%] tracking-wider ${isActive ? "text-primary" : "text-dark"}`
                  }
                >
                  Tentang Kami
                </NavLink>
              </li>
              <li className="relative">
                <Dropdown
                  label="Layanan"
                  items={serviceItems}
                  isMobile={true}
                  onItemClick={() => setIsMobile(false)}
                />
              </li>
              <li className="flex items-center">
                <NavLink
                  to={"/project-us"}
                  title="Jadwal Pelatihan"
                  aria-label="Jadwal Pelatihan"
                  onClick={() => setIsMobile(false)}
                  className={({ isActive }) =>
                    `block px-4 py-2 text-sm font-bold leading-[140%] tracking-wider ${isActive ? "text-primary" : "text-dark"}`
                  }
                >
                  Jadwal Pelatihan
                </NavLink>
              </li>
              <li className="flex items-center">
                <NavLink
                  to={"/client"}
                  title="Klien Kami"
                  aria-label="Klien Kami"
                  onClick={() => setIsMobile(false)}
                  className={({ isActive }) =>
                    `block px-4 py-2 text-sm font-bold leading-[140%] tracking-wider ${isActive ? "text-primary" : "text-dark"}`
                  }
                >
                  Klien Kami
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/blog"}
                  title="Blog"
                  aria-label="Blog"
                  onClick={() => setIsMobile(false)}
                  className={({ isActive }) =>
                    `block px-4 py-2 text-sm font-bold leading-[140%] tracking-wider ${isActive ? "text-primary" : "text-dark"}`
                  }
                >
                  Blog
                </NavLink>
              </li>
              <li>
                <NavLink to="https://wa.me/6285935140788" title="Hubungi" aria-label="Hubungi">
                  <Button customStyle="rounded-full">
                    <div className="montserrat not-italic font-bold leading-[22px] text-center text-sm tracking-[0.005em] px-2.5">
                      Hubungi
                    </div>
                  </Button>
                </NavLink>
              </li>
            </ul>
            {/* <div className="grid grid-cols-2 grid-rows-2">
              <a
                href="https://wa.me/6281297984971"
                className="flex items-center h-9"
              >
                <Icon
                  path={mdiPhoneOutline}
                  size={0.8}
                  className="text-primary"
                />
                <span className="ml-1 text-default text-xs leading-[140%] tracking-wider self-center">
                  081297984971
                </span>
              </a>
              <a
                href="mailto:cs@cleansheet.id"
                className="flex items-center h-9"
              >
                <Icon
                  path={mdiEmailOutline}
                  size={0.8}
                  className="text-primary"
                />
                <span className="ml-1 text-default text-xs leading-[140%] tracking-wider self-center">
                  cs@cleansheet.id
                </span>
              </a>
              <button
                className="flex items-center h-9"
                onClick={() =>
                  (window.location.href =
                    "https://goo.gl/maps/96DJaKdWRYMmSMYi9")
                }
              >
                <Icon
                  path={mdiGoogleMaps}
                  size={0.8}
                  className="text-primary"
                />
                <span className="ml-1 text-default text-xs leading-[140%] tracking-wider self-center">
                  Kota Bogor
                </span>
              </button>
            </div>
            <div className="flex flex-row w-full">
              <a
                href="https://facebook.com/cleansheetindonesia/"
                className="border-l-[1px] border-neutral px-4 py-2 flex h-9"
              >
                <Icon path={mdiFacebook} size={0.8} className="text-primary" />
              </a>
              <button className="border-x-[1px] border-neutral px-4 py-2 flex h-9">
                <Icon path={mdiTwitter} size={0.8} className="text-primary" />
              </button>
              <a
                href="https://www.instagram.com/cleansheet_id/"
                className="border-r-[1px] border-neutral px-4 py-2 flex h-9"
              >
                <Icon path={mdiInstagram} size={0.8} className="text-primary" />
              </a>
              <button className="border-r-[1px] border-neutral px-4 py-2 flex h-9 items-center">
                <img src={en} alt="English" className="h-full" />
                <span className="ml-1 text-dark text-xs leading-[140%] tracking-wider font-bold">
                  EN
                </span>
                {/* <Dropdown label={bahasa==="end"?"Total Fund":"Total Pendapatan"} custom="w-[300px] left-0">
            <RadioButton
              label="0-10.000.000"
              id="fund1"
              name="filter[campaigns_sum_collected_fund_between]"
              check={filterData}
              click={onChangeData}
              value="0,10000000"
            /> 
              </button>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
}

export default NavBar;
