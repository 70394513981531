import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import TabTitle from "../../../utils/TabTitle";
import {
    mdiRadioboxBlank,
    mdiRadioboxMarked,
} from "@mdi/js";
import usePrompt from "../../../hooks/usePrompt";
import ModalAlert from "../../../components/ModalAlert";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from "../../../assets/images/logo/Logo.png";
import Icon from "@mdi/react";
import { mdiArrowLeft } from "@mdi/js";
import Footer from "../../../components/LandingPage/Footer";
import NavProfile from "../Component/NavProfile";
import Button from "../../../components/Button";
import logoBNI from "../../../assets/images/payments/logo-bni.png";
import logoMandiri from "../../../assets/images/payments/logo-mandiri.png";
import logoBankPermata from "../../../assets/images/payments/logo-permatabank.png";
import logoBRI from "../../../assets/images/payments/logo-bri.png";
import logoBCA from "../../../assets/images/payments/logo-bca.png";
import logoBSI from "../../../assets/images/payments/logo-bsi.png";
import logoDana from "../../../assets/images/payments/logo-dana.png";
import logoLinkAja from "../../../assets/images/payments/logo-linkaja.png";
import logoShopeePay from "../../../assets/images/payments/logo-shopeepay.png";

import api from "../../../api";

const OrderPage = (props) => {
    TabTitle("Profile");
    const navigate = useNavigate();

    // global condition component
    const [modalOpen, setModalOpen] = useState({ status: false, type: "" });
    const [modalAlert, setModalAlert] = useState({
        alertTitle: "",
        alertMessage: "",
    });

    const handleBack = () => {
        navigate("/");
    };


    const [paymentMethodList, setPaymentMethodList] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState({ status: false, type: "" });
    const getPaymentMethod = async () => {
        try {
            const { data } = await api.get("/v1/payment-methods");
            let temp = [];
            data.data.items.forEach((item) => {
                if (item.name.includes("BNI")) {
                    temp.push({ ...item, logo: logoBNI });
                } else if (item.name.includes("Mandiri")) {
                    temp.push({ ...item, logo: logoMandiri });
                } else if (item.name.includes("Permata")) {
                    temp.push({ ...item, logo: logoBankPermata });
                } else if (item.name.includes("BRI")) {
                    temp.push({ ...item, logo: logoBRI });
                } else if (item.name.includes("BCA")) {
                    temp.push({ ...item, logo: logoBCA });
                } else if (item.name.includes("Dana")) {
                    temp.push({ ...item, logo: logoDana });
                } else if (item.name.includes("LinkAja")) {
                    temp.push({ ...item, logo: logoLinkAja });
                } else if (item.name.includes("ShopeePay")) {
                    temp.push({ ...item, logo: logoShopeePay });
                } else if (item.name.includes("BSI")) {
                    temp.push({ ...item, logo: logoBSI });
                }

                if (item.categoryId === "1") {
                    temp[temp.length - 1].category = "Virtual Account";
                } else if (item.categoryId === "2") {
                    temp[temp.length - 1].category = "E-Wallet";
                } else if (item.categoryId === "3") {
                    temp[temp.length - 1].category = "Transfer Bank";
                }
            });
            temp.sort((a, b) => {
                if (a.categoryId < b.categoryId) {
                    return -1;
                }
                if (a.categoryId > b.categoryId) {
                    return 1;
                }
                return 0;
            });

            setPaymentMethodList(temp);

        } catch ({ response }) {
            setModalAlert({
                alertTitle: `Galat ${response.status}`,
                alertMessage:
                    response.status === 0
                        ? "Tidak ada koneksi internet. Silahkan coba lagi setelah koneksi internet stabil."
                        : "Gagal mendapatkan data pembayaran dari server. Silahkan coba lagi atau hubungi admin Cleansheet.",
            });
            setIsModalOpen({ status: true, type: "alert" });
        }
    };

    useEffect(() => {

        getPaymentMethod();

    }, []);

    return (
        <>
            <ModalAlert
                isOpen={modalOpen.status && modalOpen.type === "alert"}
                closeModal={() => {
                    setModalOpen({ status: false, type: "" });
                    setModalAlert({ alertTitle: "", alertMessage: "" });
                }}
                alertTitle={modalAlert.alertTitle}
                alertMessage={modalAlert.alertMessage}
            />
            <header id="header" className="top-0 z-50 sticky">
                <nav className="border-b bg-white border-b-bright">
                    <div className="grid grid-cols-12 mx-auto lg:max-w-7xl py-5 px-4 lg:px-4 2xl:px-0">
                        <div className="col-span-2 flex items-center justify-between">
                            <img src={logo} alt="CleanSheet" className="h-9 hidden sm:block" />
                            <button className="h-9 block mr-0 sm:mr-5" onClick={handleBack}>
                                <Icon path={mdiArrowLeft} size={1} className="text-primary" />
                            </button>
                        </div>
                        <div className="col-span-10 grid grid-cols-10">
                            <div className="col-span-10">
                                <span className="not-italic montserrat text-2xl font-bold leading-[140%] tracking-[0.005em] text-primary">
                                    Akun
                                </span>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>

            <section className="font-light leading-relaxed grid grid-rows-12 grid-flow-col grid-cols-12 mx-auto px-4 lg:px-4 xl:px-0 lg:max-w-6xl relative gap-4 lg:gap-y-6 lg:gap-x-10 py-20">
                <NavProfile />
                <div className="row-span-2 col-span-8 bg-white rounded-3xl drop-shadow-bottom">

                    <div className="border-b-2 p-4 border-bright flex">
                        <p className="text-2xl text-primary font-bold">Default Payment</p>
                    </div>

                    <div className="flow-root p-4">
                        <div className="flex flex-col items-start self-stretch gap-3 -mx-6 px-6 pb-6 max-h-[400px] overflow-y-auto">
                            {paymentMethodList?.length > 0 ? (
                                paymentMethodList.map((data, index) => (
                                    <Fragment key={"paymentMethod" + data.id}>
                                        {paymentMethodList[index].category !==
                                            paymentMethodList[index - 1]?.category ? (
                                            <div className="not-italic font-bold text-sm leading-[140%] tracking-[0.005em] text-primary">
                                                {data.category}
                                            </div>
                                        ) : null}
                                        <button
                                            type="button"
                                            className={
                                                (paymentMethod
                                                    ? paymentMethod.id === data.id
                                                        ? "border-primary "
                                                        : "border-neutralBright "
                                                    : "border-neutralBright ") +
                                                "flex items-center px-4 py-1 gap-2 bg-white border rounded-2xl self-stretch"
                                            }
                                            onClick={() =>
                                                setPaymentMethod({ id: data.id, label: data.name })
                                            }
                                        >
                                            <div className="h-[44px] w-[44px] flex items-center">
                                                {data.logo && (
                                                    <img
                                                        src={data.logo}
                                                        alt={data.name}
                                                        className="w-full"
                                                    />
                                                )}
                                            </div>
                                            <div className="not-italic font-normal text-base leading-[22px] tracking-[0.005em] text-dark flex-grow text-left">
                                                {data.name}
                                            </div>
                                            <div>
                                                {paymentMethod ? (
                                                    paymentMethod.id === data.id ? (
                                                        <Icon
                                                            path={mdiRadioboxMarked}
                                                            size={1}
                                                            className="text-primary"
                                                        />
                                                    ) : (
                                                        <Icon
                                                            path={mdiRadioboxBlank}
                                                            size={1}
                                                            className="text-neutralBright"
                                                        />
                                                    )
                                                ) : (
                                                    <Icon
                                                        path={mdiRadioboxBlank}
                                                        size={1}
                                                        className="text-neutralBright"
                                                    />
                                                )}
                                            </div>
                                        </button>
                                    </Fragment>
                                ))
                            ) : (
                                <div className="not-italic font-normal text-base leading-[22px] tracking-[0.005em] text-slateLight">
                                    Saat ini tidak ada metode pembayaran yang tersedia. Mohon
                                    coba lagi nanti atau hubungi admin Cleansheet.
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            <FloatingWhatsapp />
            <footer id="footer">
                <Footer />
            </footer>
        </>
    );
}

export default connect(null)(OrderPage)
