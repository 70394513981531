import React from "react";

const Button = ({
  type = "button",
  btnType = "primary",
  isDisabled = false,
  click,
  children,
  customStyle = "",
  ...props
}) => {
  let typeStyle = "";
  if (btnType === "primary") {
    typeStyle =
      "flex flex-row rounded-2xl bg-primary text-white hover:bg-gradient-to-tl hover:from-primaryDark hover:to-primary ring-1 ring-primary px-3 py-2 gap-2 disabled:bg-primaryBright disabled:hover:from-primaryBright disabled:hover:to-primaryBright disabled:ring-primaryBright";
  } else if (btnType === "secondary") {
    typeStyle =
      "flex flex-row rounded-2xl bg-transparent text-black hover:bg-gradient-to-tl hover:from-primaryBright hover:to-primaryLight ring-1 ring-primary px-3 py-2 gap-2";
  } else if (btnType === "white") {
    typeStyle =
      "flex flex-row rounded-2xl bg-white text-primary hover:bg-gray-50 ring-1 ring-primary px-3 py-2 gap-2";
  }
  return (
    <button
      type={type}
      disabled={isDisabled}
      className={typeStyle + customStyle}
      onClick={click}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
