import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const SectionVideoSliderPromo = () => {
  return (
    <section id="video-slider-promo" className="py-4">
      <article className="max-w-container mx-auto px-6 md:px-4 flex flex-col gap-5">
        <h2 className="text-center font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat mb-8">
          Apa Kata {" "}
          <span className="text-primary">Mereka</span>?
        </h2>
        <div className="w-full">
          <Swiper
            breakpoints={{
              320: {
                slidesPerView: 1.1,
                centeredSlides: true,
              },
              640: {
                slidesPerView: 2,
                centeredSlides: false,
              },
              1024: {
                slidesPerView: 3,
                centeredSlides: false,
              },
            }}
            spaceBetween={30}
            loop={false}
          >
            <SwiperSlide>
              <div className="iframe-16-9-ratio rounded-xl">
                <iframe
                  className="absolute inset-0 w-full h-full"
                  src="https://www.youtube.com/embed/zHXQf48qS3c"
                  title="Cleansheet Indonesia"
                  aria-label="Cleansheet Indonesia"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
                  allowFullScreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="iframe-16-9-ratio rounded-xl">
                <iframe
                  className="absolute inset-0 w-full h-full"
                  src="https://www.youtube.com/embed/8V5FICw7nxM"
                  title="Cleansheet Indonesia"
                  aria-label="Cleansheet Indonesia"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
                  allowFullScreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="iframe-16-9-ratio rounded-xl">
                <iframe
                  className="absolute inset-0 w-full h-full"
                  src="https://www.youtube.com/embed/zsdpmTp-t0c"
                  title="Cleansheet Indonesia"
                  aria-label="Cleansheet Indonesia"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
                  allowFullScreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="iframe-16-9-ratio rounded-xl">
                <iframe
                  className="absolute inset-0 w-full h-full"
                  src="https://www.youtube.com/embed/FJoEuCjcgGc"
                  title="Cleansheet Indonesia"
                  aria-label="Cleansheet Indonesia"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
                  allowFullScreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="iframe-16-9-ratio rounded-xl">
                <iframe
                  className="absolute inset-0 w-full h-full"
                  src="https://www.youtube.com/embed/3Cp-POoE5dg"
                  title="Cleansheet Indonesia"
                  aria-label="Cleansheet Indonesia"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
                  allowFullScreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="iframe-16-9-ratio rounded-xl">
                <iframe
                  className="absolute inset-0 w-full h-full"
                  src="https://www.youtube.com/embed/_gfVGJ-Pn08"
                  title="Cleansheet Indonesia"
                  aria-label="Cleansheet Indonesia"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
                  allowFullScreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </article>
    </section>
  );
};

export default SectionVideoSliderPromo;
