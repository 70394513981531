import React from "react";
import {NavLink} from "react-router-dom";
import Button from "../../Button";
import pelatihan1 from "../../../assets/images/misc/pelatihan-1.jpeg";
import pelatihan2 from "../../../assets/images/misc/pelatihan-2.jpeg";

const SectionProjectKami = () => {
  return (
    <section id="ayo-bergabung-menjadi-partner-kami">
      <article className="max-w-container pt-16 pb-32 mx-auto px-6 md:px-4 lg:px-20 xl:px-48 flex flex-col gap-12">
        <h1 className="montserrat not-italic font-bold text-3xl leading-[140%] tracking-[0.005em] text-dark text-center">
          Jadwal Pelatihan
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-14 lg:gap-16">
          <div className="flex flex-col gap-6 p-6 bg-white border border-primaryBright rounded-3xl col-span-1">
            <h2 className="montserrat not-italic font-bold text-xl leading-[140%] tracking-[0.005em] text-dark text-center">
              <span className="text-primary">Public Speaking</span> For Leader & Career Development
            </h2>
            <div className="h-48 overflow-hidden">
              <img
                src={pelatihan1}
                alt="Pelatihan 1"
                className="w-full h-full object-cover object-center"
              />
            </div>
            <NavLink to={"/project-us/training/pelatihan-1"}>
              <Button customStyle=" w-full">
                <div className="w-full text-center not-italic font-bold text-sm lg:text-base leading-[140%] tracking-[0.005em] text-white py-1 px-3 md:px-5">
                    Selengkapnya
                </div>
              </Button>
            </NavLink>
          </div>
          <div className="flex flex-col gap-6 p-6 bg-white border border-primaryBright rounded-3xl col-span-1">
            <h2 className="montserrat not-italic font-bold text-2xl leading-[140%] tracking-[0.005em] text-dark text-center">
              <span className="text-primary">Mini Class</span> Statement Analysis
            </h2>
            <div className="h-48 overflow-hidden">
              <img 
                src={pelatihan2} 
                alt="Pelatihan 2" 
                className="w-full h-full object-cover object-center" 
              />
            </div>
            <NavLink to={"/project-us/training/pelatihan-2"}>
              <Button customStyle=" w-full">
                <div className="w-full text-center not-italic font-bold text-sm lg:text-base leading-[140%] tracking-[0.005em] text-white py-1 px-3 md:px-5">
                  Selengkapnya
                </div>
              </Button>
            </NavLink>
          </div>
        </div>
      </article>
    </section>
  );
};

export default SectionProjectKami;
