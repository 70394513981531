import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useLayoutEffect, useState } from "react";
import LandingPageLayout from "./layouts/LandingPageLayout";
import HomePage from "./pages/LandingPage/HomePage";
import HowToOrderPage from "./pages/LandingPage/HowToOrderPage";
import AboutPage from "./pages/LandingPage/AboutPage";
import ServicesPage from "./pages/LandingPage/ServicesPage";
import GeneralCleaningPage from "./pages/LandingPage/ServicesPage/GeneralCleaningPage";
import DeepCleaningPage from "./pages/LandingPage/ServicesPage/DeepCleaningPage";
import ToiletCleaningPage from "./pages/LandingPage/ServicesPage/ToiletCleaningPage";
import FurnitureCleaningPage from "./pages/LandingPage/ServicesPage/FurnitureCleaningPage";
import HydroCleaningPage from "./pages/LandingPage/ServicesPage/HydroCleaningPage";
import OneCorpPage from "./pages/LandingPage/ServicesPage/OneCorpPage";
import OneHomePage from "./pages/LandingPage/ServicesPage/OneHomePage";
import PenyemprotanDisinfektanPage from "./pages/LandingPage/ServicesPage/PenyemprotanDisinfektanPage";
import PolesLantaiPage from "./pages/LandingPage/ServicesPage/PolesLantaiPage";
import PartnerPage from "./pages/LandingPage/PartnerPage";
import BlogPage from "./pages/LandingPage/BlogPage";
import FormLayout from "./layouts/FormLayout";
import FormPesanPage from "./pages/FormPesan";
import FormDetailPage from "./pages/FormPesan/FormDetailPage";
import FormBayarPage from "./pages/FormPesan/FormBayarPage";
import JoinUsPage from "./pages/LandingPage/JoinUsPage";
import Rangers from "./pages/LandingPage/JoinUsPage/Rangers";
import Heroes from "./pages/LandingPage/JoinUsPage/Heroes";
import LoginPage from "./pages/Auth/LoginPage";
import RegisterPage from "./pages/Auth/RegisterPage";
import { store, persistor } from './services/Store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import PrivateRoute from './navigations/PrivateRoute';
import PublicRoute from './navigations/PublicRoute';
import AccountPage from "./pages/ProfilePage/AccountPage";
import PrivateLayout from "./layouts/PrivateLayout";
import AddressPage from "./pages/ProfilePage/AddressPage";
import OrderPage from "./pages/ProfilePage/OrderPage";
import ChangePasswordPage from "./pages/ProfilePage/ChangePasswordPage";
import VoucherPage from "./pages/ProfilePage/VoucherPage";
import DefaultPaymentPage from "./pages/ProfilePage/DefaultPaymentPage";
import ProjectUsPage from "./pages/LandingPage/ProjectUsPage";
import PublicTrainingPage from "./pages/LandingPage/ServicesPage/PublicTrainingPage";
import InhouseTrainingPage from "./pages/LandingPage/ServicesPage/InhouseTrainingPage";
import ManPowerPage from "./pages/LandingPage/ServicesPage/ManPowerPage";
import TrainingPage from "./pages/LandingPage/ProjectUsPage/Training";

function App() {
  const [transactionCode, setTransactionCode] = useState("");

  return (
    <Wrapper>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes>
            <Route path={"/"} element={<LandingPageLayout />}>
              <Route index element={<HomePage />} />
              <Route path={"/about"} element={<AboutPage />} />
              <Route path={"/services"}>
                <Route index element={<ServicesPage />} />
                <Route
                  path={"/services/general-cleaning"}
                  element={<GeneralCleaningPage />}
                />
                <Route
                  path={"/services/deep-cleaning"}
                  element={<DeepCleaningPage />}
                />
                <Route
                  path={"/services/toilet-cleaning"}
                  element={<ToiletCleaningPage />}
                />
                <Route
                  path={"/services/furniture-cleaning"}
                  element={<FurnitureCleaningPage />}
                />
                <Route
                  path={"/services/hydro-vaccum"}
                  element={<HydroCleaningPage />}
                />
                <Route
                  path={"/services/poles-lantai"}
                  element={<PolesLantaiPage />}
                />
                <Route
                  path={"/services/penyemprotan-disinfektan"}
                  element={<PenyemprotanDisinfektanPage />}
                />
                <Route
                  path={"/services/one-home-one-hope"}
                  element={<OneHomePage />}
                />
                <Route
                  path={"/services/one-corp-many-hope"}
                  element={<OneCorpPage />}
                />
                <Route
                  path={"/services/public-training"}
                  element={<PublicTrainingPage />}
                />
                <Route
                  path={"/services/inhouse-training"}
                  element={<InhouseTrainingPage />}
                />
                <Route
                  path={"/services/man-power-supply"}
                  element={<ManPowerPage />}
                />
              </Route>
              <Route path={"/how-to-order"} element={<HowToOrderPage />} />
              <Route path={"/client"} element={<PartnerPage />} />
              <Route path={"/join-us"}>
                <Route index element={<JoinUsPage />} />
                <Route path={"/join-us/rangers"} element={<Rangers />} />
                <Route path={"/join-us/heroes"} element={<Heroes />} />
              </Route>
              <Route path={"/blog"} element={<BlogPage />} />
              <Route path={"/project-us"}>
                <Route index element={<ProjectUsPage />} />
                <Route path="/project-us/training/:id" element={<TrainingPage />} />
              </Route>
              <Route path={"/login"} element={<LoginPage />} />
              <Route path={"/register"} element={<RegisterPage />} />
              <Route path={"/*"} element={<Navigate to="/" replace />} />
            </Route>
            <Route path={"/profile"} element={<PrivateLayout />} >
              <Route index element={<AccountPage />} />
              <Route path={"/profile/addresses"} element={<AddressPage />} />
              <Route path={"/profile/orders"} element={<OrderPage />} />
              <Route path={"/profile/password"} element={<ChangePasswordPage />} />
              <Route path={"/profile/vouchers"} element={<VoucherPage />} />
              <Route path={"/profile/payments"} element={<DefaultPaymentPage />} />
              <Route path={"/profile/*"} element={<Navigate to="/profile" replace />} />
            </Route>
            <Route
              path={"/form"}
              element={<FormLayout transactionCode={transactionCode} />}
            >
              <Route index element={<FormPesanPage />} />
              <Route path={"/form/confirmation"} element={<FormDetailPage />} />
              <Route
                path={"/form/payment"}
                element={
                  <FormBayarPage setTransactionCodeValue={setTransactionCode} />
                }
              />
              <Route path={"/form/*"} element={<Navigate to="/form" replace />} />
            </Route>
          </Routes>
        </PersistGate>
      </Provider>
    </Wrapper>
  );
}

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

export default App;
