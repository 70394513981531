import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import TabTitle from "../../../utils/TabTitle";
import ModalAlert from "../../../components/ModalAlert";
import React, { useState } from "react";
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from "../../../assets/images/logo/Logo.png";
import Icon from "@mdi/react";
import { mdiArrowLeft } from "@mdi/js";
import Footer from "../../../components/LandingPage/Footer";
import { mdiCircleEditOutline } from "@mdi/js";
import NavProfile from "../Component/NavProfile";
import Button from "../../../components/Button";

const AddressPage = (props) => {
    TabTitle("Profile");
    const navigate = useNavigate();

    // global condition component
    const [modalOpen, setModalOpen] = useState({ status: false, type: "" });
    const [modalAlert, setModalAlert] = useState({
        alertTitle: "",
        alertMessage: "",
    });

    const handleBack = () => {
        navigate("/");
    };

    return (
        <>
            <ModalAlert
                isOpen={modalOpen.status && modalOpen.type === "alert"}
                closeModal={() => {
                    setModalOpen({ status: false, type: "" });
                    setModalAlert({ alertTitle: "", alertMessage: "" });
                }}
                alertTitle={modalAlert.alertTitle}
                alertMessage={modalAlert.alertMessage}
            />
            <header id="header" className="top-0 z-50 sticky">
                <nav className="border-b bg-white border-b-bright">
                    <div className="grid grid-cols-12 mx-auto lg:max-w-7xl py-5 px-4 lg:px-4 2xl:px-0">
                        <div className="col-span-2 flex items-center justify-between">
                            <img src={logo} alt="CleanSheet" className="h-9 hidden sm:block" />
                            <button className="h-9 block mr-0 sm:mr-5" onClick={handleBack}>
                                <Icon path={mdiArrowLeft} size={1} className="text-primary" />
                            </button>
                        </div>
                        <div className="col-span-10 grid grid-cols-10">
                            <div className="col-span-10">
                                <span className="not-italic montserrat text-2xl font-bold leading-[140%] tracking-[0.005em] text-primary">
                                    Akun
                                </span>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>

            <section className="font-light leading-relaxed grid grid-rows-12 grid-flow-col grid-cols-12 mx-auto px-4 lg:px-4 xl:px-0 lg:max-w-6xl relative gap-4 lg:gap-y-6 lg:gap-x-10 py-20">
                <NavProfile />
                <div className="row-span-2 col-span-8 bg-white rounded-3xl drop-shadow-bottom">

                    <div className="border-b-2 p-4 border-bright flex">
                        <div className="flex items-center space-x-4">

                            <p className="text-2xl text-primary font-bold">Alamat saya</p>

                            <Button
                                typeNavigation='button'
                                btnType="primary"
                                btnStyle='primary'
                                customStyle='px-4 py-0.5 w-fit rounded-full'>
                                Tambah Alamat
                            </Button>

                        </div>

                    </div>


                    <div className="flow-root p-4">
                        <ul role="list" className="divide-y divide-bright dark:divide-gray-700">
                            <li className="py-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="flex-1 min-w-0">
                                        <p className="text-md font-medium text-gray-900 truncate dark:text-white">
                                            Rumah <span className="leading-loose font-normal text-default text-xs pl-1">Alamat Utama</span>
                                        </p>
                                        <p className="text-md font-medium text-gray-900 truncate dark:text-white">
                                            Ardelia Apti
                                        </p>
                                        <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                            0834992131414
                                        </p>
                                        <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                            Jl. Ir. H. Juanda No.13, Paledang, Kecamatan Bogor Tengah, Kota Bogor, Jawa Barat 16122
                                        </p>
                                    </div>
                                    <Icon path={mdiCircleEditOutline} size={0.8} className="text-primary" />
                                </div>
                            </li>
                            <li className="py-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="flex-1 min-w-0">
                                        <p className="text-md font-medium text-gray-900 truncate dark:text-white">
                                            Kantor
                                        </p>
                                        <p className="text-md font-medium text-gray-900 truncate dark:text-white">
                                            Ardelia Apti
                                        </p>
                                        <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                            0834992131414
                                        </p>
                                        <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                            Jl. Ir. H. Juanda No.13, Paledang, Kecamatan Bogor Tengah, Kota Bogor, Jawa Barat 16122
                                        </p>
                                    </div>
                                    <Icon path={mdiCircleEditOutline} size={0.8} className="text-primary" />
                                </div>
                            </li>
                            <li className="py-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="flex-1 min-w-0">
                                        <p className="text-md font-medium text-gray-900 truncate dark:text-white">
                                            Gudang
                                        </p>
                                        <p className="text-md font-medium text-gray-900 truncate dark:text-white">
                                            Ardelia Apti
                                        </p>
                                        <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                            0834992131414
                                        </p>
                                        <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                            Jl. Ir. H. Juanda No.13, Paledang, Kecamatan Bogor Tengah, Kota Bogor, Jawa Barat 16122
                                        </p>
                                    </div>
                                    <Icon path={mdiCircleEditOutline} size={0.8} className="text-primary" />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <FloatingWhatsapp />
            <footer id="footer">
                <Footer />
            </footer>
        </>
    );
}

export default connect(null)(AddressPage)
