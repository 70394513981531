import React from "react";
import { useParams } from "react-router-dom";
import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import SectionTraining from "../../../components/LandingPage/ProjectUsSection/SectionTraining";
import TabTitle from "../../../utils/TabTitle";

const TrainingPage = () => {
  TabTitle("Training - GStep");

  const { id } = useParams(); 

  return (
    <>
      <SectionTraining id={id}/>
      <FloatingWhatsapp />
    </>
  );
};

export default TrainingPage;
