import axios from 'axios';

const url = `${process.env.REACT_APP_API_URL}/v1`;

const postLogin = (data) => {
  return{
    type: 'POST_LOGIN',
    payload: axios.post(`${url}/sign-in`, data)
  }
}

const postRegister = (data) => {
    return{
        type: 'POST_REGISTER',
        payload: axios.post(`${url}/register`, data)
    }
}

export {
    postLogin,
    postRegister
}
