import model from "../../../assets/images/misc/man_power_supply.jpg";
import guarantee from "../../../assets/images/icons/guarantee.png";
import plant from "../../../assets/images/icons/plant.png";
import time from "../../../assets/images/icons/time.png";
import vaccine from "../../../assets/images/icons/vaccine.png";
import price from "../../../assets/images/icons/price.png";
import doctor from "../../../assets/images/icons/doctor.png";
import team from "../../../assets/images/icons/team.png";
import rating from "../../../assets/images/icons/rating.png";

function SectionKenapa({ background = "bg-light" }) {
  return (
    <section
      className={"py-[68px] relative " + background}
      id="partner-terbaik"
    >
      <article className="max-w-container mx-auto px-6 md:px-4">
        <div className="mt-[16px] font-bold text-2xl flex  md:text-[32px] leading-[140%] tracking-wider montserrat">
          Kenapa Harus&nbsp;
          <span className="text-primary ">GStep</span>&nbsp;Indonesia
        </div>
        <div className="grid grid-cols-3 mt-[44px]">
          <div className="col-span-3 2xl:col-span-2 grid grid-cols-1 sm:grid-cols-2 gap-y-4 lg:pr-24">
            <div className="flex">
              <img src={team} alt="" className="w-12 h-12" />
              <div className="ml-4">
                <div className="text-dark lg:text-[22px] font-bold leading-[140%] tracking-wider">
                  Tenaga Profesional
                </div>
                <div className="text-dark text-base font-normal leading-[140%] tracking-wider">
                  Kami memiliki tenaga professional dan berpengalaman di bidangnya
                </div>
              </div>
            </div>
            <div className="flex">
              <img src={rating} alt="" className="w-12 h-12" />
              <div className="ml-4">
                <div className="text-dark lg:text-[22px] font-bold leading-[140%] tracking-wider">
                  Modern & menyenangkan
                </div>
                <div className="text-dark text-base font-normal leading-[140%] tracking-wider">
                  Suasana training yg modern & menyenangkan
                </div>
              </div>
            </div>
            <div className="flex">
              <img src={time} alt="" className="w-12 h-12" />
              <div className="ml-4">
                <div className="text-dark lg:text-[22px] font-bold leading-[140%] tracking-wider">
                  Fleksibel
                </div>
                <div className="text-dark text-base font-normal leading-[140%] tracking-wider">
                  Waktu pelaksanaan yang fleksibel dan menyenangkan
                </div>
              </div>
            </div>
            <div className="flex">
              <img src={guarantee} alt="" className="w-12 h-12" />
              <div className="ml-4">
                <div className="text-dark lg:text-[22px] font-bold leading-[140%] tracking-wider">
                  Komprehensif
                </div>
                <div className="text-dark text-base font-normal leading-[140%] tracking-wider">
                  Materi Training yg Komprehensif
                </div>
              </div>
            </div>
          </div>
          <div className="hidden 2xl:block col-span-3 xl:col-span-1 relative">
            <img src={model} alt="" className="h-full z-10" />
          </div>
        </div>
      </article>
    </section>
  );
}

export default SectionKenapa;
