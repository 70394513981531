import {Navigate, Outlet} from "react-router-dom";
import {connect} from 'react-redux';
import React from "react";

const accessToken = localStorage.getItem('accessToken');

/** force authorized out from auth page */
const PublicRoute = (props) => (
    accessToken? <Outlet/> : <Navigate to={{
        pathname: '/',
        state: { from: props.location }
    }} />
)

const mapStateToProps = (state) => {
    return{
      auth: state.auth,
    }
}

export default connect (mapStateToProps) (PublicRoute);
