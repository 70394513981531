import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import SectionKenapa from "../../../components/LandingPage/HomeSection/SectionKenapa";
import SectionJasa from "../../../components/LandingPage/SectionJasa";
import SectionDeepCleaning from "../../../components/LandingPage/ServicesSection/DeepCleaning/SectionDeepCleaning";
import SectionLayanan from "../../../components/LandingPage/ServicesSection/PublicTraining/SectionLayanan";
import SectionProblems from "../../../components/LandingPage/ServicesSection/DeepCleaning/SectionProblems";
import SectionTestimoni from "../../../components/LandingPage/ServicesSection/DeepCleaning/SectionTestimoni";
import SectionVideo from "../../../components/LandingPage/ServicesSection/DeepCleaning/SectionVideo";
import TabTitle from "../../../utils/TabTitle";

const PublicTrainingPage = () => {
    TabTitle("Layanan Deep Cleaning Profesional - Cleansheet");
    return (
        <>
            <SectionLayanan />
            <FloatingWhatsapp />
        </>
    );
}

export default PublicTrainingPage;