import React from "react";
import pelatihan1 from "../../../assets/images/misc/pelatihan-1.jpeg";
import pelatihan2 from "../../../assets/images/misc/pelatihan-2.jpeg";

const SectionTraining = ({id}) => {
  return (
    <section id="training">
      <article className="max-w-container mx-auto px-6 md:px-4 pt-16 grid grid-cols-1">
        <div className="col-span-12 md:col-span-7 lg:col-end-13 lg:col-span-5 flex items-end">
            {id === "pelatihan-1" ? (
                <img src={pelatihan1} alt="bergabung-partner" />
            ) : (
                <img src={pelatihan2} alt="bergabung-partner" />
            )}
        </div>
      </article>
    </section>
  );
};

export default SectionTraining;
