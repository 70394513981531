import { useState, useEffect } from "react"; // {{ edit_1 }}
import model from "../../../assets/images/misc/banner.png";
import model2 from "../../../assets/images/misc/banner-2.jpg"
import model3 from "../../../assets/images/misc/banner-3.jpg"
import model4 from "../../../assets/images/misc/banner-4.jpg"
import model5 from "../../../assets/images/misc/banner-5.jpg"
import {mdiArrowRight} from "@mdi/js";
import Icon from "@mdi/react";
import {Link} from "react-router-dom";

function SectionBanner() {
  const images = [ // {{ edit_2 }}
    model,
    model2,
    model3,
    model4,
    model5
    // Add more image paths here
  ];
  const [currentIndex, setCurrentIndex] = useState(0); // {{ edit_3 }}
  const [fade, setFade] = useState(true); // {{ edit_4 }}

  useEffect(() => { // {{ edit_4 }}
    const interval = setInterval(() => {
      setFade(false); // Start fade out
      setTimeout(() => {
        setCurrentIndex((currentIndex + 1) % images.length);
        setFade(true); // Start fade in
      }, 300); // Duration of fade out
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [currentIndex, images.length]); // {{ edit_5 }}

  return (
    <section className="relative h-screen" id="beranda">
      <img 
        src={images[currentIndex]} 
        alt="Banner Modal" 
        className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-300 ${fade ? 'opacity-100' : 'opacity-0'}`} // {{ edit_6 }}
      />
      {/* Add shadow overlay */}
      <div className="absolute inset-0 bg-black opacity-30" /> {/* {{ edit_7 }} */}
      {/* Add navigation buttons for the slider */}
      <div className="relative z-10 flex flex-col justify-center items-center h-full px-6 md:px-4 max-w-container mx-auto"> {/* {{ edit_1 }} */}
        <article className="flex flex-col gap-6 col-span-1 sm:col-span-6 md:col-span-4 text-center"> {/* {{ edit_2 }} */}
          <h1 className="montserrat text-white font-bold text-3xl lg:text-[48px] tracking-[0.005em] leading-[140%] lg:leading-[120%]"> {/* {{ edit_3 }} */}
            Selamat Datang di GSTEP Indonesia
          </h1>
          
        </article>
        <Link
            to="/services"
            className="inline-block font-medium text-lg leading-[140%] tracking-wider text-white border border-white px-4 py-2 rounded mt-4" // {{ edit_4 }}
          >
            Tentang Kami
          </Link>
      </div>
    </section>
  );
}

export default SectionBanner;
