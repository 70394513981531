import {NavLink} from "react-router-dom";
import Button from "../Button";

function SectionJasa({title}) {
  return (
    <section className="py-16 bg-primaryLight" id="services">
      <article className="relative max-w-container mx-auto px-6 md:px-4">
        <h2 className="text-center font-bold text-[32px] leading-[130%] tracking-wider text-dark montserrat mb-4">
          Ingin Menggunakan Jasa{" "}
          <span className="text-primary">Cleansheet</span>?
        </h2>
        <div className="mt-auto flex justify-center">
        <NavLink to={"/form"}>
          <Button customStyle="center-web rounded-xl">
            <div className="px-2 text-white font-bold">
              Pesan Layanan Cleansheet
            </div>
          </Button>
        </NavLink>
        </div>
      </article>
    </section>
  );
}

export default SectionJasa;
