import FloatingWhatsapp from "../../../components/LandingPage/FloatingWhatsapp";
import SectionApp from "../../../components/LandingPage/HomeSection/SectionApp";
import TabTitle from "../../../utils/TabTitle";
import React from "react";
import TextField from "../../../components/TextField";
import Button from "../../../components/Button";
import { postRegister } from '../../../services/Actions/Auth';
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {NavLink} from "react-router-dom";
import Select from 'react-select';
import { useState, useEffect } from 'react';
import {getReferenceSources} from "../../../services/Actions/ReferenceSources";

const RegisterPage = (props) => {
    TabTitle("Daftar");
    const navigate = useNavigate();
    const referenceSource = useSelector(state => state.referenceSources);
    const [currentHolder, setCurrentHolder] = useState({});
    const [referenceSources, setReferenceSources] = useState([]);
    const [formStatus, setFormStatus] = useState('empty');
    const [errorValidations, setErrorValidations] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);
        if (!data.email || !data.password) {
            alert('tidak boleh kosong')
        }else{
            await props.dispatch(postRegister(data))
                .then((res) => {
                    let token = res?.value?.data?.data?.accessToken??null
                    let type = res?.value?.data?.data?.tokenType??null
                    localStorage.setItem('accessToken', token)
                    localStorage.setItem('tokenType', type)
                    navigate('/')
                })
                .catch((error) => {
                    let errors = error.response?.data?.error?.errors??[]
                    setErrorValidations(errors)
                })
        }
    }

    useEffect(() => {

        const fetchBankList = () => {
            props.dispatch(getReferenceSources())
                .then((res) => {
                    let items = res?.value?.data?.data?.items??[]
                    items = items.map((item) => ({
                        label: item.name,
                        value: item.id,
                    }));
                    setReferenceSources(items);
                    setCurrentHolder({});
                })
                .catch((error) => {
                    setReferenceSources([]);
                })
        };
        fetchBankList();
    }, []);

    return (
        <>
            <section className="grid grid-cols-1 sm:grid-cols-12 mx-auto px-4 lg:px-4 xl:px-0 lg:max-w-7xl relative gap-10 lg:gap-y-6 lg:gap-x-10 py-20">
                <div className="col-span-1 sm:col-span-12 lg:col-end-10 lg:col-span-5 p-6 bg-white rounded-3xl drop-shadow-bottom">
                    <div className="flex flex-col gap-1 pb-10">
                        <div className="font-bold flex justify-start md:justify-center sm:text-[24px] leading-[140%] tracking-wider montserrat">Selamat Bergabung!</div>
                        <div className="flex justify-start md:justify-center text-left md:text-center sm:text-[18px] leading-[140%] tracking-wider ">Silahkan lengkapi data di bawah ini.</div>
                    </div>
                    <form className="flex flex-col gap-6"
                          id="form-login"
                          onSubmit={handleSubmit}
                    >
                        <div className="flex flex-col gap-2">
                            <TextField
                                isRequired={true}
                                label={"Nama Lengkap"}
                                inputId={"name"}
                                type={"text"}
                                inputName={"name"}
                                placeholder={"Masukkan nama lengkap anda"}
                                helper={
                                    errorValidations.find(element => element.location === "name") && (
                                        <span className="text-red100">{errorValidations.find(element => element.location === "name")?.message??""}</span>
                                    )
                                }
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <TextField
                                isRequired={true}
                                label={"Email"}
                                inputId={"email"}
                                type={"text"}
                                inputName={"email"}
                                placeholder={"Masukkan email anda"}
                                helper={
                                    errorValidations.find(element => element.location === "email") && (
                                        <span className="text-red100">{errorValidations.find(element => element.location === "email")?.message??""}</span>
                                    )
                                }
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <TextField
                                isRequired={true}
                                label={"Nomor HP"}
                                inputId={"phone"}
                                type={"number"}
                                inputName={"phone"}
                                placeholder={"Masukkan nomor HP anda"}
                                helper={
                                    errorValidations.find(element => element.location === "phone") && (
                                        <span className="text-red100">{errorValidations.find(element => element.location === "phone")?.message??""}</span>
                                    )
                                }
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <TextField
                                isRequired={true}
                                label={"Password"}
                                inputId={"password"}
                                type={"password"}
                                inputName={"password"}
                                placeholder={"Masukkan password anda"}
                                helper={
                                    errorValidations.find(element => element.location === "password") && (
                                        <span className="text-red100">{errorValidations.find(element => element.location === "password")?.message??""}</span>
                                    )
                                }
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <TextField
                                isRequired={true}
                                label={"Ulangi Password"}
                                inputId={"passwordConfirmation"}
                                type={"password"}
                                inputName={"passwordConfirmation"}
                                placeholder={"Masukkan password anda kembali"}
                                helper={
                                    errorValidations.find(element => element.location === "passwordConfirmation") && (
                                        <span className="text-red100">{errorValidations.find(element => element.location === "passwordConfirmation")?.message??""}</span>
                                    )
                                }
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <div className="flex flex-row items-center gap-1.5"><label>Referensi</label><div className="w-1.5 h-1.5 rounded-full bg-red100"></div></div>
                            <div className='relative w-full'>
                                <Select
                                    className='basic-single font-inter'
                                    required
                                    classNamePrefix='select'
                                    defaultValue={currentHolder.item}
                                    options={referenceSources}
                                    noOptionsMessage={() =>
                                        'No data'
                                    }
                                    isClearable
                                    isLoading={referenceSource.isLoading}
                                    onChange={() => setFormStatus('empty')}
                                    isDisabled={
                                        referenceSource.isLoading || formStatus === 'checking'
                                    }
                                    placeholder={
                                        <div className='text-neutralDefault'>Pilih Referensi</div>
                                    }
                                    isSearchable
                                    components={{
                                        IndicatorSeparator: () => null,
                                    }}
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            border:
                                                state.menuIsOpen || state.isFocused
                                                    ? '1px solid #90CAF9'
                                                    : '1px solid #B0BEC5',
                                            backgroundColor:
                                                state.menuIsOpen || state.isFocused
                                                    ? '#FFFFFF'
                                                    : '#F5F7F8',
                                            borderRadius: '1rem',
                                            padding: '0 0.5rem',
                                            boxShadow: 'none',
                                            color: state.isDisabled ? '#B0BEC5' : '#607D8B',
                                            '&:hover': {},
                                        }),
                                        singleValue: (provided) => ({
                                            ...provided,
                                            color: '#607D8B',
                                        }),
                                        loadingMessage: (provided) => ({
                                            ...provided,
                                            color: '#607D8B',
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            backgroundColor: '#FFFFFF',
                                            borderRadius: '1rem',
                                            overflow: 'hidden',
                                            boxShadow: 'none',
                                            border: '1px solid #90CAF9',
                                        }),
                                        menuList: (provided) => ({
                                            ...provided,
                                            paddingTop: '0',
                                            paddingBottom: '0',
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            color:
                                                (state.isSelected && '#FFFFFF') ||
                                                (state.isDisabled ? '#B0BEC5' : '#607D8B'),
                                            '&:hover': {
                                                backgroundColor: state.isSelected
                                                    ? '#0D47A1'
                                                    : '#90CAF9',
                                                color:
                                                    (state.isSelected && '#FFFFFF') ||
                                                    (state.isDisabled ? '#B0BEC5' : '#607D8B'),
                                            },
                                        }),
                                    }}
                                    name='referenceSourceId'
                                />
                            </div>
                            {
                                errorValidations.find(element => element.location === "referenceSourceId") &&
                                (<div className="not-italic font-normal text-sm leading-5 text-default">
                                    <span className="text-red100">{errorValidations.find(element => element.location === "referenceSourceId")?.message??""}</span></div>)
                            }
                        </div>

                        <Button type="submit" form={"form-login"} btnType="primary" isDisabled={referenceSource.isLoading}>
                            <div className="w-full text-center">{referenceSource.isLoading? 'Loading...' : 'Daftar'}</div>
                        </Button>

                        <div className="flex flex-col gap-1 pb-10">
                            <div
                                className="flex justify-start md:justify-center text-left md:text-center sm:text-[18px] leading-[140%] tracking-wider">
                                Sudah memiliki akun?&nbsp;<NavLink to="/login" className="text-primary">login di sini</NavLink>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        <SectionApp />
        <FloatingWhatsapp />
        </>
    );
}

export default connect (null) (RegisterPage)
